<template>
    <div>
        <div class="load-filter-btn-wrapper">
            <a @click="removeHistory" class="mt-3 mr-3">
                Remove
            </a>
            <b-button
                :disabled="selectedRow === null"
                class="applyButton mt-2 mr-3"
                @click="loadHistory()"
            >
                Load
            </b-button>
        </div>
        <div class="filter-table-wrapper mt-3 flt-tbl ">
            <ejs-grid
                :dataSource="dataSource"
                @rowSelected="dataSetRowSelected"
                :allowPaging="true"
                :pageSettings="pageSettings"
            >
                <e-columns>
                    <e-column
                        field="CreationDate"
                        width="100"
                        headerText="Date Created"
                    ></e-column>
                    <e-column
                        field="Name"
                        width="100"
                        headerText="Name"
                    ></e-column>
                    <e-column
                        field="Description"
                        width="100"
                        headerText="Description"
                    ></e-column>
                    <e-column
                        field="DateLastUsed"
                        width="100"
                        headerText="Date Used"
                    ></e-column>
                </e-columns>
            </ejs-grid>
        </div>
    </div>
</template>

<script>
import Vue from 'vue';
import { mapActions, mapState, mapGetters } from 'vuex';
import moment from 'moment';
import {
    GridPlugin,
    Sort,
    Page,
    Filter,
    Freeze,
    CommandColumn,
    Search,
    Resize,
    Toolbar,
    Grid,
    ExcelExport,
} from '@syncfusion/ej2-vue-grids';
Vue.use(GridPlugin);
export default {
    provide: {
        grid: [
            Sort,
            Page,
            Filter,
            Freeze,
            CommandColumn,
            Search,
            Toolbar,
            Grid,
            Resize,
            ExcelExport,
        ],
    },
    data() {
        return {
            selectedIndx: -1,
            dataSetId: 0,
            ProjectID: 0,
            selectedFilters: null,
            pageSettings: {
                pageSize: 15,
                pageSizes: [12, 50, 100, 200],
                pageCount: 4,
            },
        };
    },
    methods: {
        ...mapActions('gridFilters', {
            setCurrentGridFilter: 'setCurrentGridFilter',
            setLastUsedDate: 'setLastUsedDate',
        }),
        removeHistory() {},
        loadHistory() {
            let tempGridFilters = {
                dataSetId: parseInt(this.dataSetId),
                projectId: parseInt(this.projectId),
                selectedFilters: [...this.selectedFilters],
            };
            this.setCurrentGridFilter(tempGridFilters);
            this.setLastUsedDate(this.selectedIndx);
            //then set the tool to data and load the correct data set grid
            this.$emit('navigateToDataTool');
        },
        dataSetRowSelected(args) {
            this.dataSetId = args.data.dataSetId;
            this.projectId = args.data.projectId;
            this.selectedFilters = args.data.selectedFilters;
            this.selectedIndx = args.data.unSortedIndex;
        },
        getUTCDate(dateParm) {
            var stillUtc = moment.utc(dateParm).toDate();
            return (
                moment(stillUtc)
                    .local()
                    .format('MM/DD/yyyy') +
                ' ' +
                moment(stillUtc)
                    .local()
                    .format('LT')
            );
        },
    },
    computed: {
        ...mapState({
            gridFilters: (state) => state.gridFilters.historyFilters,
            selectedProject: (state) => state.projects.selectedProjects[0],
        }),
        dataSource() {
            return this.gridFilters
                .filter((gf) => {
                    return gf.projectId === this.selectedProject.ProjectID;
                })
                .map((gf) => {
                    return {
                        CreationDate: this.getUTCDate(gf.createdDate),
                        Name: gf.Name,
                        Description: gf.Description,
                        DateLastUsed: this.getUTCDate(gf.DateLastUsed),
                        dataSetId: gf.dataSetId,
                        projectId: gf.projectId,
                        selectedFilters: gf.selectedFilters,
                        unSortedIndex: gf.unSortedIndex,
                    };
                })
                .sort((a, b) => {
                    return new Date(b.CreationDate) - new Date(a.CreationDate);
                });
        },
    },
    mounted() {},
    activated() {},
};
</script>

<style>
@import url(https://cdn.syncfusion.com/ej2/material.css);
.load-filter-btn-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}
</style>
